import { HttpResponse, HttpErrorResponse, } from "@angular/common/http";
import { AuthenticationService } from "./authentication.service";
import "rxjs/add/operator/do";
import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
import * as i2 from "@angular/router";
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    TokenInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var currentUser = localStorage.getItem("currentUser");
        var signintype = localStorage.getItem("signintype");
        var companyId = localStorage.getItem("companyId");
        var accessToken = localStorage.getItem("x-access-token");
        var userType = localStorage.getItem("userType");
        if (userType == "EMPLOYEE") {
            userType = "EMPLOYEE";
        }
        else {
            userType = "null";
        }
        if (accessToken &&
            signintype == "normal" &&
            !request.url.endsWith("verifyOtp")) {
            var headerObject = {
                userType: userType,
            };
            if (accessToken && !request.url.endsWith('exit'))
                headerObject["accessToken"] = accessToken;
            else if (currentUser)
                headerObject["x-user-token"] = currentUser;
            if (companyId)
                headerObject["companyId"] = companyId;
            request = request.clone({
                setHeaders: headerObject,
            });
        }
        else if (currentUser &&
            (signintype == "LinkedIn" || signintype == "Google")) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Bearer " + currentUser,
                    Tokentype: "" + signintype,
                    companyId: "" + companyId,
                    userType: userType,
                },
            });
        }
        if ((request.url.includes("login") || request.url.includes("user")) &&
            request.method == "POST") {
            request.headers.append("Cache-Control", "no-cache, no-store, must-revalidate, post-check=0, pre-check=0");
            request.headers.append("Pragma", "no-cache");
            request.headers.append("Expires", "0");
        }
        return next.handle(request).do(function (event) {
            if (event instanceof HttpResponse) {
            }
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401 && !err.url.includes("/login/verifyOtp")) {
                    _this.router.navigateByUrl("/");
                }
            }
        });
    };
    TokenInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function TokenInterceptor_Factory() { return new TokenInterceptor(i0.inject(i1.AuthenticationService), i0.inject(i2.Router)); }, token: TokenInterceptor, providedIn: "root" });
    return TokenInterceptor;
}());
export { TokenInterceptor };
