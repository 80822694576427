import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import "rxjs/add/operator/do";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentUser = localStorage.getItem("currentUser");
    const signintype = localStorage.getItem("signintype");
    let companyId = localStorage.getItem("companyId");
    let accessToken = localStorage.getItem("x-access-token");
    let userType = localStorage.getItem("userType");
    if (userType == "EMPLOYEE") {
      userType = "EMPLOYEE";
    } else {
      userType = "null";
    }

    if (
      accessToken &&
      signintype == "normal" &&
      !request.url.endsWith("verifyOtp")
    ) {
      const headerObject = {

        userType: userType,
      }
      if(accessToken && !request.url.endsWith('exit'))  headerObject["accessToken"] = accessToken;
      else if(currentUser) headerObject["x-user-token"]= currentUser;

      if(companyId) headerObject["companyId"] = companyId
      request = request.clone({
        setHeaders: headerObject,
      });
    } else if (
      currentUser &&
      (signintype == "LinkedIn" || signintype == "Google")
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser}`,
          Tokentype: `${signintype}`,
          companyId: `${companyId}`,
          userType: userType,
        },
      });
    }
    if (
      (request.url.includes("login") || request.url.includes("user")) &&
      request.method == "POST"
    ) {
      request.headers.append(
        "Cache-Control",
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
      );
      request.headers.append("Pragma", "no-cache");
      request.headers.append("Expires", "0");
    }

    return next.handle(request).do(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && !err.url.includes("/login/verifyOtp")) {
            this.router.navigateByUrl("/");
          }
        }
      }
    );
  }
}
