export const environment = {
  production: false,
  name: "dev",
  baseURL: "https://dev-api.hissa.com/",
  googleSignInURL: "https://dev-api.hissa.com/google/signin",
  linkedInSignInURL: "https://dev-api.hissa.com/linkedin/signin",
  googleSignUpURL: "https://dev-api.hissa.com/google/signup",
  linkedInSignUpURL: "https://dev-api.hissa.com/linkedin/signup",
  empPortalUrl: "https://dev-my.hissa.com",
  optionsUrl: "https://dev-options.hissa.com",
  newCaptableBaseUrl : "https://dev-captable-api.hissa.com/v1",
  newCaptableUrl : "https://dev-captable.hissa.com"
};
